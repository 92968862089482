<template>
  <div>
    <h2>Entregas por Região</h2>
    <div class="">
      <div class="region-container" v-for="(region, i) in regions" :key="'region-'+i">
        <h3>{{ regionsName[i] }} - {{ region.length }} CTes</h3>

        <v-data-table
          :items="region"
          :headers="[
              {text: 'CTe', value: 'number'},
              {text: 'Destino', value: 'recipients.name'},
              {text: 'Cidade', value: 'recipients.cities.name'},
              {text: 'Peso', value: 'weigth'},
              {text: 'Frete', value: 'freight_value'},
          ]"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.number`]="{ item }">
            <span>
              <a :href="'/#/editar-cte/'+item.id" target="_blank">{{ item.number }}</a>
            </span>
          </template>
          <template v-slot:[`item.weigth`]="{ item }">
            <span>{{ parseFloat(item.weigth).toFixed(2).toString().replace('.', ',') }} Kg</span>
          </template>
          <template v-slot:[`item.freight_value`]="{ item }">
            <span>{{ $functions.moneyFormat(item.freight_value) }}</span>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>

import http from '@/modules/http'

export default {
  mounted() {
    this.getCtes();
  },
  data() {
    return {
      ctes: [],
      regions: [],
      blocks: [],
      regionsName: [],
    }
  },
  methods: {
    setCtes() {
      let i = 0;
      this.regionsName.forEach(r => {
        this.ctes.forEach(c => {
          if(c?.recipients?.cities?.regions?.name == r) {
            this.regions[i].push(c);
          }
        })
        i ++;
      })
    },
    setRegions() {
      let regions = [];

      this.ctes.forEach(e => {
        if(e.recipients.cities.regions && !regions.includes(e.recipients.cities.regions.name)) {
          regions.push(e.recipients.cities.regions.name);
        }
      });

      regions.forEach(r => {
        this.regionsName.push(r);
        this.regions.push([]);
      });

      this.setCtes();
    },
    getCtes() {
      http.get('api/ctes?condition=region')
      .then(res => {
        this.ctes = res.data;

        this.setRegions();
      })
      .catch(err => {
        this.$side({
          type: "error",
          msg: "Erro de conexão com servidor",
          duration: 4000,
        });
        console.log(err);
      })
    }
  },
}
</script>

<style scoped>
  .region-container {
    background: aliceblue;
  }
  .region-container h3 {
    text-align: center;
    padding: 20px;
  }
</style>