var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Entregas por Região")]),_c('div',{},_vm._l((_vm.regions),function(region,i){return _c('div',{key:'region-'+i,staticClass:"region-container"},[_c('h3',[_vm._v(_vm._s(_vm.regionsName[i])+" - "+_vm._s(region.length)+" CTes")]),_c('v-data-table',{attrs:{"items":region,"headers":[
            {text: 'CTe', value: 'number'},
            {text: 'Destino', value: 'recipients.name'},
            {text: 'Cidade', value: 'recipients.cities.name'},
            {text: 'Peso', value: 'weigth'},
            {text: 'Frete', value: 'freight_value'} ],"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
        var item = ref.item;
return [_c('span',[_c('a',{attrs:{"href":'/#/editar-cte/'+item.id,"target":"_blank"}},[_vm._v(_vm._s(item.number))])])]}},{key:"item.weigth",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.weigth).toFixed(2).toString().replace('.', ','))+" Kg")])]}},{key:"item.freight_value",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$functions.moneyFormat(item.freight_value)))])]}}],null,true)})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }